.tippy-box[data-theme~='cpopover'] {
   background:white;
   color: inherit;
   border: 1px solid rgba(0, 0, 21, 0.2);
 }
 .tippy-box[data-theme~='cpopover'] > .tippy-content {
   padding: 0;
 }
 .tippy-box[data-theme~='cpopover'] > .tippy-arrow::before {
   color: white;
 }
